







































































































/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import NotRecords from "@/components/NotRecords.vue";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { getAPI } from "@/api/axios-base";
import Errors from "../PdfSign/Errors.vue";
import PatientFilter from "./PatientFilter.vue";
import Patient from "@/models/Patient";
import { AxiosError } from "axios";
export default Vue.extend({
  name: "crm-transfer-patients",
  components: {
    NotRecords,
    Errors,
    // PatientFilter,
  },
  data() {
    return {
      isAdmins: false,
      options: {},
      filterPa: {
        doctorUuid: null,
        range: { limit: 100, offset: 0 },
      },
      singleSelect: false,
      signature: new Blob(),
      loadingSignStep1: false,
      showErrors: false,
      doctorSelect: false,
      transferDialog: false,
      doctorUuid: "",
      doctorstotransfer: [],
      errorMessage: "",
      loadingSendStep: false,
      headers: [
        { text: this.$t("Fullname"), value: "fullname", sortable: false },
        { text: this.$t("labelGender"), value: "isMale", sortable: false },
        { text: "Email", value: "email", align: "end", sortable: false },
        {
          text: this.$t("labelHomePhone"),
          value: "homePhone",
          align: "end",
          sortable: false,
        },
        // { text: "", value: "actions", sortable: false },
        // { text: "", value: "data-table-expand" },
      ],
      selected: [],

      currentPatient: {
        fullname: "",
        phone: "",
        email: "",
        uuid: "",
      },
      dialogEdit: false,
      dialogConfirmRemove: false,
      dialogDocument: false,
      dialogSignStep1: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", [
      "patients",
      "doctors",
      "agents",
      "loading",
    ]),
    ...mapState("crmEmployeeModule", ["workers"]),
    ...mapGetters(["isSuper", "isAdmin", "getpatientN"]),
    disableWhileLoading(): boolean {
      return this.loading || this.loadingSignStep1 || this.loadingSendStep;
    },

    selecteds() {
      if (this.selected.length != 0) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    if (this.isSuper || this.isAdmin) {
      this.isAdmins = true;
      this.init();
    }
  },
  methods: {
    ...mapActions("crmMedicFormModule", [
      "actRemovePatient",
      "actFilterPatient",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmMedicFormModule", ["mutSetPatients"]),
    showDetails(patient: Patient) {
      this.$router.push(`/patients/details/${patient.uuid}`);
    },
    employeeSelect(valor: boolean) {
      this.doctorSelect = valor;
      this.selected = [];
    },
    getPatients() {
      const { page, itemsPerPage }: any = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 10;
      this.filterPa.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };

      if (this.filterPa.doctorUuid == null) {
        this.mutSetPatients([]);

        this.selected = [];
        this.doctorstotransfer = this.workers;
        this.doctorSelect = false;
        return;
      } else {
        this.actFilterPatient({
          doctorUuid: this.filterPa.doctorUuid,
          range: this.filterPa.range,
        });
      }
    },

    async init() {
      await this.actGetEmployees();
      this.mutSetPatients([]);

      this.selected = [];
      this.doctorstotransfer = this.workers;
      this.doctorSelect = false;
    },
    closedTransfer() {
      this.transferDialog = false;
    },
    transfer() {
      const patientTransfer = this.selected.map((patient: any) => patient.uuid);
      getAPI
        .put("/patient/transfer", {
          patientsUuid: patientTransfer,
          coodinatorUuid: this.doctorUuid,
        })
        .then(() => {
          notifySuccess(this.$t("medicForm.notificationPatientTransfer"));
          this.init();
          this.closedTransfer();
          (this as any).$refs.patientfilterId.reset();
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.closedTransfer();
          this.loading = false;
        });
    },
  },
});
